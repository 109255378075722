<template>
  <v-app class>
    <div>
     <v-alert
      v-model="alert"
      border="left"
      dark
      dismissible
      class="alert"
      :class="{'alert-error':alertType=='error', 'alert-success':alertType=='success'}"
    >
    {{alertMessage}}
        </v-alert>
    </div>
    <div class="row">
      <div class="col-md-7 ml-auto mr-auto">
        <v-container>
          <div class="mb-8 mt-8 text-center">
            <h1>Pending Departments</h1>
          </div>
          <!-- table  -->
          <v-data-table
              loading-text="Loading departments... Please wait"
              :loading="!departments.length"
              :headers= 'headers'
              :items="departments"
             >
                <template v-slot:item.statusDate="{ item }" >
                  {{item.statusDate | formatDate}}
              </template>
                <template v-slot:item.actions="{ item }" >
                    <div class="d-flex">
                      <Button :btnType="'Submit'" :label="'Approve'" @onClick="openConfModal(item)" class="mr-2"/>
                    <Button :btnType="'Cancel'" :label="'Reject'" @onClick="openDeleteModal(item)" />
                    </div>
              </template>
          </v-data-table>
        </v-container>
      </div>
    </div>
     <ConfirmationDialog :title="'Are you sure you want to submit?'" :isLoading="isLoading" :dialog="dialog" :btnTitle="'Yes'" @close="dialog = false" @btnAction="approve"/>
  </v-app>
</template>
<script>
import Button from '@/components/ui/form/Button.vue'
import ConfirmationDialog from '@/components/ui/confirmationDialog.vue'
import {
  APPROVE_DEPARTMENT,
  FETCH_PENDING_DEPARTMENT
} from '@/store/action-type'

export default {
  components: {
    ConfirmationDialog,
    Button
  },
  data () {
    return {
      alertMessage: '',
      dialog: false,
      alert: false,
      alertType: '',
      item: null,
      isLoading: false,
      headers: [
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Date',
          value: 'statusDate'
        },
        {
          text: 'Action',
          value: 'action'
        },
        {
          text: 'Actions',
          value: 'actions'
        }
      ]
    }
  },
  computed: {
    departments () {
      return this.$store.getters.pendingDepartments
    }
  },
  methods: {
    showAlertMessage (message, type) {
      this.alertMessage = message
      this.alert = true
      this.alertType = type
    },
    approve () {
      this.isLoading = true
      this.$store
        .dispatch(APPROVE_DEPARTMENT, this.item)
        .then((result) => {
          this.showAlertMessage('Approve department successfully', 'success')
          // eslint-disable-next-line handle-callback-err
        })
        .catch(() => {
          this.showAlertMessage('Approve department failed', 'error')
        }).finally(() => {
          this.isLoading = false
          this.dialog = false
        })
    },
    openConfModal (item) {
      this.item = item
      this.dialog = true
    },
    deleteItem (item) {

    }
  },
  mounted () {
    this.$store
      .dispatch(FETCH_PENDING_DEPARTMENT)
      .then(() => {})
      .catch((error) => {
        if (error) {
          this.showAlertMessage('There are no items pending your approval', 'warning')
        }
      })
  }
}
</script>
<style scoped>
.search-btn {
  background-color: #e6e3e3;
  height: 40px;
  border-radius: 3px !important;
}
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
.alert {
  position: fixed;
  z-index: 9999;
  top: 5px;
  min-width: 50%;
}
.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-error {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
</style>
